<template>
  <div class="h-fill">
    <template v-if="!$parent.appInner && openFromAppInner && purchasedCard">
      <div class="display-flex justify-content-center pd-t-80">
        <div class="text-align-center">
          <div>
            <van-icon name="checked" class="c-app-green fs-100"/>
          </div>
<!--          <div>
            支付成功，请返回APP下载资源！
          </div>-->
        </div>
      </div>
    </template>
    <template v-else-if="card.createAt">
      <div class="bg-f">
        <div class="justify-content-space-between align-items-center pd-t-15 pd-l-15 pd-r-15">
          <div v-if="$parent.appInner" class="c-app-green" @click="onBackClick">
            首页
          </div>
          <div v-else @click="onBackClick">
            <van-icon name="arrow-left"/>
          </div>
          <div class="align-items-center fs-12 c-9">
            <div @click="onBuyKnowClick">购买须知</div>
            <div class="mg-l-5 mg-r-5">|</div>
            <div @click="onShareClick">分享</div>
            <div class="mg-l-5 mg-r-5">|</div>
            <!--<div v-if="!$parent.appInner || $parent.isAndroid" class="mg-l-5 mg-r-5">|</div>-->
            <div @click="onReportClick">举报</div>
            <div v-if="card.teamInfo.length > 0" @click="onTeamRuleClick">
              <span class="mg-l-5 mg-r-5">|</span>
              <span>拼团规则</span>
            </div>
          </div>
        </div>
      </div>
      <div class="pd-15">
<!--        <div v-if="notice.message">
          <div id="notice" class="pd-10 fs-14 bdr-10 mg-b-10 justify-content-space-between">
            <div class="c-f mg-r-10 display-flex align-items-center" @click="onClickNotice(notice.url)">
              <img src="/h5/static/images/home_banner_icon_emoji.png" height="25"/>
              <span class="text-ellipsis-1">{{ notice.message }}</span>
            </div>
            <div class="" @click="onClickReadNotice()">
              <van-icon name="cross" style="vertical-align: middle" class="c-f fs-14 text-strong"></van-icon>
            </div>
          </div>
        </div>-->
        <div class="justify-content-space-between ">
          <div class="fs-22 flex-1 lh-28">
          <span v-if="card.mark" class="pd-l-6 pd-r-6 display-inline-block lh-22 h-22 bdr-2 fs-12 mg-r-5"
                style="background: linear-gradient(51deg, #262626 0%, #545454 100%);color: #FFC680;vertical-align: middle;"
          >{{ card.mark }}</span>
            <span class="text-strong" style="vertical-align: middle;">{{ card.title }}</span>
          </div>
          <div class="w-100 fs-12 c-app-green text-align-right mg-t-4 text-strong">
            {{ card.levelName }} / {{ card.subjectName }}
          </div>
        </div>

        <div class="justify-content-space-between mg-t-10">
          <div class="align-items-center fs-12">
            <!--            <img class="mg-r-5" width="18" src="/h5/static/images/resource-icon.png"/>-->
            <div class="c-app-orange fs-14" v-if="card.price > 0">
              <template v-if="$parent.isIos && $parent.appInner">{{ card.price / 100 }}学习币</template>
              <template v-else>￥ {{ card.price / 100 }}</template>
            </div>
            <div class="mg-l-5 mg-r-5">|</div>
            <div>大小 {{ formatFileSize }}</div>
            <div class="mg-l-5 mg-r-5">|</div>
            <template v-if="card.cardCount > -1">
              <div>卡片 {{ card.cardCount }}</div>
            </template>

            <div class="c-app-orange" v-else>免费</div>
          </div>
          <div class="align-items-center fs-12 c-9">
            <div>{{ card.hotValue }}</div>
          </div>
        </div>
        <div class="display-flex fs-12">
          <div v-for="tag,index in card.tags" :key="tag.tagId"
               class="bg-f3f pd-l-5 pd-r-5 pd-t-2 pd-b-2 bdr-2 mg-r-10 mg-t-10">{{ tag.tagName }}
          </div>
        </div>
        <div class="h-1 mg-t-15" style="border-bottom: 1px #ccc dashed"></div>
        <div class="justify-content-space-between mg-t-10">
          <div class="c-7">
            <div v-if="!card.scoreNew" class="text-align-center fs-14">
              <div v-if="card.evaluateUserCount > 5">
                <div>{{ card.score }} 评分</div>
                <!--<div class="fs-12 c-9 mg-t-2">{{card.evaluateUserCount}}人评分</div>-->
              </div>
              <div v-else class="c-9">
                累计中
              </div>
              <div v-if="card.evaluateUserCount >= 5" class="mg-t-4 mg-l-4">
                <van-rate size="14" allow-half v-model="card.score" color="#FFB904" readonly/>
              </div>
              <div v-else class="mg-t-4 mg-l-4">
                <van-rate size="14" allow-half color="#cccccc" readonly/>
              </div>
            </div>
            <div v-else class="fs-12">
              <div class="pd-b-5">
                <div class="display-inline-block mg-r-5">内容质量</div>
                <van-rate size="14" allow-half v-model="card.qualityScore" color="#FFB904" readonly/>
              </div>
              <div class="pd-b-5">
                <div class="display-inline-block mg-r-5">界面美观</div>
                <van-rate size="14" allow-half v-model="card.uiScore" color="#FFB904" readonly/>
              </div>
              <div>
                <div class="display-inline-block mg-r-5">售后服务</div>
                <van-rate size="14" allow-half v-model="card.afterSalesScore" color="#FFB904" readonly/>
              </div>
            </div>
          </div>
          <div class="align-items-center">
            <div>
              <div class="pd-8 fs-12 pd-r-20 pd-l-20 bg-app-orange c-f bdr-4" @click="subScore">评分</div>
              <van-popup v-model="showScore" round closeable style="width: 75%">
                <div class="bg-f pd-20">
                  <div class="fs-18 mg-t-20">
                    <div class="pd-b-20 mg-l-10">
                      <div class="mg-r-5 display-inline-block">资源内容质量</div>
                      <van-rate size="18" v-model="score.quality_score" color="#FFB904"/>
                    </div>
                    <div class="pd-b-20 mg-l-10">
                      <div class="display-inline-block mg-r-5">卡片界面美观</div>
                      <van-rate size="18" v-model="score.ui_score" color="#FFB904"/>
                    </div>
                    <div class="pd-b-20 mg-l-10">
                      <div class="display-inline-block">作者提供售后</div>
                      <div class="display-inline-block">
                        <van-radio-group v-model="score.is_after_sales" class="align-items-center">
                          <van-radio class="mg-l-6 mg-r-15" name="1">是</van-radio>
                          <van-radio name="2">否</van-radio>
                        </van-radio-group>
                      </div>
                    </div>
                    <div class="pd-b-20 mg-l-10" v-if="score.is_after_sales==1">
                      <div class="display-inline-block mg-r-5">作者售后服务</div>
                      <van-rate size="18" v-model="score.after_sales_score" color="#FFB904"/>
                    </div>
                    <van-field
                        class="fs-16"
                        type="textarea"
                        v-model="score.score_content"
                        placeholder="说点什么吧"
                        rows="3"
                        border
                        autosize
                        maxlength="50"
                        show-word-limit
                    />
                    <div class="pd-8 pd-r-20 pd-l-20 bg-app-orange text-align-center c-f bdr-4"
                         @click="saveScore">
                      评价
                    </div>
                  </div>
                </div>
              </van-popup>
            </div>

            <!--<div v-if="card.cardId && !card.evaluated" class="align-items-center fs-12">
              <div class="border-1 c-app-green bdc-app-green w-55 lh-22 text-align-center bdr-4"
                   @click="onEvaluateClick(2)">不推荐
              </div>
              <div class="mg-l-10 c-f bg-app-green w-55 lh-24 text-align-center bdr-4" @click="onEvaluateClick(1)">推荐
              </div>
            </div>
            -->
          </div>
        </div>
      </div>
      <!--下载过或者购买过-->
      <div class="h-12 bg-f3f"></div>
      <template v-if="underwayList.length > 0">
        <div class="justify-content-space-between align-items-center pd-l-15 pd-r-15 fs-12"
             style="border-bottom: 1px #e8e8e8 solid">
          <div><span class="c-app-red">{{ underwayUsers }}</span>人在拼团，可直接参与</div>
          <div class="c-9 pd-t-10 pd-b-10" @click="showAllUnderwayList = true">查看全部&gt;</div>
        </div>
        <div class="justify-content-space-between fs-12 pd-15">
          <div class="align-items-center">
            <img :src="underwayList[randomUnderwayIndex].avatar" class="w-30 h-30 bdr-circular mg-r-10"/>
            <div>
              {{ underwayList[randomUnderwayIndex].nickname }}
            </div>
          </div>
          <div class="align-items-center">
            <div class="mg-r-10">
              <div class="text-align-right">还差<span
                  class="c-app-red">{{ underwayList[randomUnderwayIndex].quota }}</span>人
              </div>
              <div>剩余 {{ underwayList[randomUnderwayIndex].countDownTxt }}</div>
            </div>
            <div class="bg-app-orange c-f bdr-5 pd-5" @click="onUnderwayTeamClick(underwayList[randomUnderwayIndex])">
              去拼团
            </div>
          </div>
        </div>
        <div class="h-12 bg-f3f"></div>
      </template>
      <div class="pd-15">
        <div class="justify-content-space-between align-items-center">
          <div class="align-items-center" @click="onUserClick">
            <img :src="userAvatar" class="w-30 h-30 bdr-circular"/>
            <div class="fs-12 mg-l-10">{{ userNickname }}</div>
          </div>
          <div class="fs-12 c-9 align-items-center">
            更新于：{{ card.editAt ? card.editAt : card.createAt }}
          </div>
        </div>
        <template v-if="card.contact.length > 5">
          <div
              v-if="(card.price > 0 && card.purchased) || (card.price === 0 && card.downloadTimes > 0) || card.owner"
              class="pd-10 mg-t-10 fs-14 bg-f3f bdr-5">售后联系方式：{{ card.contact }}
          </div>
          <div v-else class="pd-8 mg-t-10 fs-12 bg-f9f bdr-5 c-9">此作者已提供售后，购买后可见</div>
        </template>
        <!--tap页-->
        <van-tabs v-model="active" color="#2E3DE6" title-active-color="#2E3DE6" @click="onTabClick">
          <van-tab title="介绍" key=0>
            <div class="mg-t-10 card-content" style="min-height: 100px" v-if="card.content"
                 v-html="card.content"></div>
            <div class="mg-t-10 fs-14 c-9 text-align-center lh-60" v-if="card.cardId && !card.content">暂无介绍</div>
          </van-tab>
          <van-tab title="目录" class="text-strong" key=1>
            <div v-if="allDecks">
              <deck-item class="mg-t-20" :decks="allDecks" :activedid="activeDid"></deck-item>
            </div>
            <div class="mg-t-20 c-9 fs-14 text-align-center lh-60" v-else>
              暂无目录
            </div>
          </van-tab>
          <van-tab title="预览" key=2>
            <van-popup v-if="isPreview" v-model="isPreview" closeable round
                       position="bottom"
                       :style="{ height: '90%' }">
              <card-viewer :did="preview.cardList[0].did"
                           :cardId="card.cardId"
                           @previewClose="previewClose"
                           :isPreview="isPreview"
                           :cardList="preview.cardList"
                           :currentCardIndex="currentCardIndex"
                           :currentEditCard="currentEditCard"
              ></card-viewer>
            </van-popup>
            <div class="mg-t-20 c-9 fs-14 text-align-center lh-60" v-else>
              暂无预览
            </div>
          </van-tab>
        </van-tabs>
      </div>
      <div class="h-1 bg-d"></div>
      <div class="c-6 fs-14 mg-15 text-strong">
        <van-icon name="warning-o" color="#0FE7AB"/>
        版权声明：平台资源均由用户制作上传，若存在侵权行为，请发送反馈信件至ankimemory@163.com联系平台进行处理，感谢理解！
      </div>
      <div class="c-app-orange fs-14 mg-15 text-strong">
        <span class="c-app-orange">【购买须知】</span>：此资源为第三方用户上传提供，资源网为公益平台不参与抽成和收取任何费用，作者提供售后的如需退款请与作者协商沟通，如无法联系作者再联系平台客服为您提供帮助。
      </div>
      <div class="pd-b-100">
        <div v-if="userOther.length > 0">
          <div class="pd-20 bg-f3f c-app-green text-strong">作者其他资源</div>
          <div v-for="item in userOther" style="border-bottom: 1px #eeeeee solid">
            <card-item :card="item"></card-item>
          </div>
        </div>
        <div v-if="recommend.length > 0">
          <div class="pd-20 bg-f3f c-app-green text-strong">平台其他资源</div>
          <div v-for="item in recommend" style="border-bottom: 1px #eeeeee solid">
            <card-item :card="item"></card-item>
          </div>
        </div>
      </div>
      <div class="position-fixed b-0 w-fill bg-f safe-bottom" style="border-top: #f3f3f3 1px solid;">
<!--        <div v-if="card.vipDiscountEnable" class="text-strong">
          <div class="arrow-div pd-t-10 pd-b-10 pd-l-5 pd-r-15 text-align-right" style="font-size: 12px;"
               v-if="!card.isForeverVip && !card.isVip">
            <img style="vertical-align: middle" src="/h5/static/images/super_heroes_normal@3x.png" height="18">
            <span style="vertical-align: middle">
              超级学霸全平台付费资源最低享<span class="c-app-orange text-strong">{{ card.vipYjDiscount }}折</span>
              <span v-if="card.vipSaveMoney>500">，此资源预计省
                <span class="c-app-orange text-strong">
                  {{
                    (card.vipSaveMoney) / 100
                  }}元
                </span>
              </span>
            </span>
          </div>
          <div v-if="card.isVip" class="pd-t-10 pd-b-5 pd-l-5 pd-r-15"
               style="font-size: 12px">
            <img style="vertical-align: middle" src="/h5/static/images/super_heroes_normal@3x.png" height="18">
            <span style="vertical-align: middle">
              您已开通超级学霸，已享
              <span v-if="card.isForeverVip" class="c-app-orange text-strong">
                    {{
                  card.vipYjDiscount
                }}折
              </span>
              <span v-else class="c-app-orange text-strong">
                    {{
                  card.vipTempDiscount
                }}折
              </span>
            </span>
          </div>
        </div>-->
        <div v-if="card.deleted === 1">
          <div class="bg-app-red text-align-center lh-50 c-f flex-1">该资源已被删除</div>
        </div>
        <div v-else-if="card.status === 2 || card.status === 3">
          <div class="bg-app-red text-align-center lh-50 c-f flex-1">该资源已下架</div>
        </div>
        <div v-else-if="team && team.status == 1">
          <div @click="onTeamDetailClick" class="bg-app-orange text-align-center lh-60 c-f flex-1">
            查看拼团（{{ team.payCount }}/{{ team.users }}）
          </div>
        </div>
        <div v-else class="display-flex align-items-center">
          <div
              v-if="!card.owner && card.price > 0 && !card.purchased && card.hasCode && (!$parent.appInner || !$parent.isIos)"
              class="pd-l-15 pd-r-15 fs-14 c-app-green lh-60" @click="onInputExchangeCodeClick"
              style="background-color: #CCFFF0">兑换码
          </div>
          <div v-if="!purchasedCard && unSupportPlatform()" class="bg-a text-align-center lh-60 c-f flex-1">
            此卡牌暂不支持该系统
          </div>
          <template v-else-if="!purchasedCard">
            <div v-if="card.teamInfo.length > 0" class="flex-1 display-flex align-items-center">
              <div class="flex-2 bg-app-green align-items-center justify-content-center c-f h-60"
                   @click="onDownloadClick">
                <div>
                  <div class="text-align-center">
                    <span v-if="$parent.isIos && $parent.appInner">{{ card.vipPrice / 100 }}学习币</span>
                    <span v-else>￥ {{ card.vipPrice / 100 }}</span>
                  </div>
                  <div class="fs-12 text-align-center">立即购买</div>
                </div>
              </div>
              <div class="flex-2 bg-app-orange align-items-center justify-content-center c-f h-60"
                   @click="onTeamOrderClick">
                <div>
                  <div class="text-align-center">
                      <span v-if="$parent.isIos && $parent.appInner">  {{
                          card.teamInfo[0]['vipPrice'] / 100
                        }}学习币</span>
                    <span v-else>  ￥ {{ card.teamInfo[0]['vipPrice'] / 100 }}</span>
                  </div>
                  <div class="fs-12 text-align-center">发起拼团</div>
                </div>
              </div>
            </div>
            <div v-else class="bg-app-orange text-align-center lh-60 c-f flex-1"
                 @click="onDownloadClick">
              <div>
                <span v-if="$parent.isIos && $parent.appInner">{{ card.vipPrice / 100 }}学习币购买</span>
                <span v-else>￥ {{ card.vipPrice / 100 }}购买</span>
              </div>
            </div>
<!--            <div style="background-color: #3E3737; color: #FCAE16"
                 v-if="card.vipDiscountEnable && !card.isForeverVip && !card.isVip"
                 class="pd-l-10 pd-r-10 lh-60 fs-14 text-align-center" @click="toVip">立即开通
            </div>-->
          </template>
          <template v-else>
            <div class="bg-app-green text-align-center lh-60 c-f flex-1" @click="onDownloadClick">
              下载卡牌
            </div>
          </template>
        </div>
      </div>

      <van-dialog v-model="showPayResult" title="支付提示" show-cancel-button confirmButtonText="已支付"
                  cancelButtonText="未支付" @confirm="onPayConfirm" @cancel="onPayCancel">
        <div class="mg-20">
          <div class="text-align-center">是否已支付完成？</div>
          <div v-if="$parent.appInner && copyPayUrl" class="c-app-red fs-14 mg-t-20">正在发起支付，若无反应请复制链接到浏览器打开<span
              class="c-app-blue"
              @click="onCopyPayUrlClick">点我复制链接</span>
          </div>
        </div>
      </van-dialog>
      <van-dialog v-model="showPublishNotice" title="发布资源" show-cancel-button confirmButtonText="确定">
        <div class="mg-20">
          <div class="text-align-center">
<!--            抱歉移动端未适配，如需更好的展示效果请使用电脑浏览器访问【Anki中文资源网】file.ankichinas.cn 进行发布操作-->
          </div>
        </div>
      </van-dialog>
      <van-dialog v-model="showInputExchangeCode" title="使用兑换码获取" show-cancel-button confirmButtonText="确定"
                  @confirm="onConfirmExchangeClick">
        <div class="mg-20">
          <van-field v-model="exchangeCode" placeholder="请输入兑换码"/>
        </div>
      </van-dialog>
      <van-popup v-model="showAllUnderwayList" :close-on-click-overlay="false">
        <div class="w-350">
          <div class="bg-f pd-10 bdr-10">
            <div class="text-align-center fs-14">全部拼团</div>
            <div class="" style="max-height: 500px;overflow-y: scroll">
              <div v-for="item in underwayList" class="justify-content-space-between fs-12 pd-15 "
                   style="border-bottom: 1px solid #e8e8e8">
                <div class="align-items-center">
                  <img :src="item.avatar" class="w-30 h-30 bdr-circular mg-r-10"/>
                  <div>
                    {{ item.nickname }}
                  </div>
                </div>
                <div class="align-items-center">
                  <div class="mg-r-10">
                    <div class="text-align-right">还差<span class="c-app-red">{{ item.quota }}</span>人</div>
                    <div>剩余 {{ item.countDownTxt }}</div>
                  </div>
                  <div @click="onUnderwayTeamClick(team)" class="bg-app-orange c-f bdr-5 pd-5">
                    去拼团
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-align-center">
            <span @click="showAllUnderwayList = false"
                  class="display-inline-block mg-t-20 border-1 bdc-f bdr-circular w-20 lh-20 c-f">×</span>
          </div>
        </div>
      </van-popup>
    </template>
    <van-action-sheet
        v-model="showPayOptions"
        cancel-text="取消"
        close-on-click-action>
      <div class="bg-f pd-20 fs-18">
        <div class="c-a fs-14 text-align-center mg-b-30">请选择支付方式</div>
        <div @click="onPaySelectClick('alipay_h5')" class="justify-content-center align-items-center pd-10">
          <img src="/h5/static/images/alipay.png" class="w-20">
          <div class="w-120 text-align-center">支付宝</div>
        </div>
        <div class="h-1 bg-f3f mg-t-15 mg-b-10"></div>
        <div v-if="!morePayWay" @click="morePayWay = true"
             class="justify-content-center align-items-center pd-10 mg-t-10">
          <div class="w-200 text-align-center c-6 fs-12">点此使用其他支付方式&gt;&gt;</div>
        </div>
        <template v-else>
          <div @click="onPaySelectClick('wechat')" class="justify-content-center align-items-center pd-10 mg-t-10">
            <img src="/h5/static/images/wechatpay.png" class="w-20">
            <div class="w-120 text-align-center">微信支付</div>
          </div>
        </template>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>

import cardItem from '../components/cardItem'
import deckItem from '../components/deckItem'
import cardViewer from "../components/cardViewer";

import request from '../libs/api.request'
import util from '../libs/util'
import storage from "../libs/storage";

export default {
  name: "Card",
  components: {
    cardViewer,
    cardItem,
    deckItem,
  },
  data() {
    return {
      notice: {},
      active: 0,
      activeDid: null,
      allDecks: {},
      isPreview: false,
      showScore: false,
      score: {
        'quality_score': 0,
        'ui_score': 0,
        'is_after_sales': '1',
        'after_sales_score': 0,
        'score_content': '',
      },
      currentCardIndex: 0,//当前点击的卡牌的索引
      currentEditCard: {},
      cardId: this.$route.params.cardId,
      morePayWay: false,
      downloading: false,
      card: {},
      team: null,
      userAvatar: '',
      userNickname: '',
      userOther: [],
      recommend: [],
      coin: 0,
      appCoin: 0,
      selectPackage: 0,
      rechargePackage: [],
      showPublishNotice: false,
      hasImport: false,
      showPayResult: false,
      showAllUnderwayList: false,
      copyPayUrl: '',
      showInputExchangeCode: false,
      showPayOptions: false,
      exchangeCode: "",
      preview: {},
      showAllPreview: false,
      orderType: '',//订单类型  卡牌或者积分
      openFromAppInner: false, //从app复制打开
      canDownload: false,//是否允许非APP内下载免费卡牌
      downloadApp: false,
      underwayList: [],
      randomUnderwayTeam: null,
      underwayUsers: 0,
      underwayCountDowner: null,
    }
  },
  computed: {
    formatFileSize() {
      if (this.card.fileSize > 1024 * 1024) {
        return (this.card.fileSize / (1024 * 1024)).toFixed(1) + "MB"
      } else if (this.card.fileSize > 1024) {
        return (this.card.fileSize / (1024)).toFixed(1) + "KB"
      } else {
        return this.card.fileSize + "B"
      }
    },
    purchasedCard() {//已购
      return this.card.owner || this.card.price == 0 || this.card.purchased;
    }
  },
  watch: {
    $route(to, from) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.cardId = this.$route.params.cardId;
      this.getData();
    },
    isPreview: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal)
        if (!newVal) {
          if (this.active == 2) {
            this.active = 0;
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    onBuyKnowClick() {
      this.$dialog.alert({message: "此资源为第三方用户上传提供，资源网为公益平台不参与抽成和收取任何费用\n\n作者提供售后的如需退款请与作者协商沟通，如无法联系作者在联系平台客服为您提供帮助"}).then(() => {
      })
    },
    subScore() {
      request.request({
        url: '/card/subScore',
        data: {card_id: this.cardId},
        method: 'post'
      }).then((data) => {
        this.showScore = true;
      }).catch((err) => {
        if (err.code === 401 || err.code === 403) {
          this.$parent.login();
          return;
        }
        this.$toast(err.message)
      })
    },
    saveScore() {
      request.request({
        url: '/card/score',
        data: {...{card_id: this.cardId}, ...this.score},
        method: 'post'
      }).then((data) => {
        this.score = {
          'quality_score': 0,
          'ui_score': 0,
          'after_sales_score': 0,
          'score_content': '',
        };
        this.showScore = false;
        this.$toast('评价已提交！');
      }).catch((err) => {
        if (err.code === 401 || err.code === 403) {
          this.$parent.login();
          return;
        }
        this.$toast(err.message)
      })
    },
    toVip() {
      if (!this.$parent.loginUser) {
        this.$parent.login();
      } else {
        if (this.$parent.isIos && this.$parent.appInner) {
          if (util.compareVersionIfMax(this.$parent.appVersion, '1.13.2')) {
            akBridge.callHandler("openVIPPage");
          } else {
            this.$dialog.alert({message: '咦跳不过去了，请返回app设置页面查看详情哦'}).then(() => {
            })
          }
        } else {
          this.$router.push({'path': '/vip', query: {chan: 1}})
        }
      }
    },
    onTeamDetailClick() {
      this.$router.push({'path': '/team/' + this.team.teamId})
    },
    unSupportPlatform() {
      if (
        this.$parent.appInner &&
        this.card.platform.length > 0) {
        let p = this.$parent.isIos ? 2 : 1;
        return this.card.platform.indexOf(p) === -1;
      }

      return false;
    },
    onPaySelectClick(payType) {
      if (this.downloading) {
        return;
      }

      if (payType.indexOf("wechat") > -1) {
        if (util.inWechatPC()) {
          this.$dialog.alert({message: "请使用手机版微信打开链接支付"});
          return;
        }
        payType = util.inWechat() ? 'wechat_gzh' : 'wechat_h5'
      } else if (payType.indexOf("alipay") > -1) {
        if (util.inWechat()) {
          this.$parent.showCommonMask("微信内不支持支付宝", "请打开外部浏览器进行支付");
          return;
        }
      } else {
        this.$dialog.alert({
          message: '错误的支付方式',
        });
      }

      this.downloading = true;
      request.request({
        url: '/card/order',
        data: {
          isMobile: /windows phone|iphone|android/ig.test(window.navigator.userAgent) ? 1 : 0,
          cardId: this.cardId,
          returnUrl: location.href,
          appInner: this.$parent.appInner ? 1 : 0,
          payType: payType,
          orderType: this.orderType,
          wxOpenId: this.$parent.wxOpenId
        },
        method: 'post'
      }).then((data) => {
        this.downloading = false;
        if (this.orderType === 'team') {
          localStorage.setItem("jumpTeamOrder", 1);
        }
        if (util.inWechat()) {
          WeixinJSBridge.invoke('getBrandWCPayRequest', data.payData, (res) => {
            if (res.err_msg === "get_brand_wcpay_request:ok") {
              this.showPayOptions = false;
              this.$toast.loading({message: '正在查询支付结果', duration: 60000, overlay: true});
              setTimeout(() => {
                location.reload();
              }, 3000);
            }
          });
        } else {
          this.copyPayUrl = data.copyPayUrl;
          if (this.$parent.appInner && this.$parent.isAndroid && util.compareVersionIfMax('3.00.12', this.$parent.appVersion) && payType === 'wechat_h5') {
            this.$dialog.confirm({
              message: '请复制微信支付链接，然后打开手机浏览器粘贴前往支付',
              confirmButtonText: '复制链接'
            }).then(() => {
              this.$parent.execCopy(this.copyPayUrl, false);
              setTimeout(() => {
                this.$dialog.confirm({message: '链接已复制，请支付成功后点击【确认】刷新页面'}).then(() => {
                  location.reload();
                })
              }, 300)
            })
          } else {
            setTimeout(() => {
              this.showPayResult = true;
              this.showPayOptions = false;
            }, 800);
            location.assign(data.payUrl);
          }
        }
      }).catch((err) => {
        setTimeout(() => {
          this.downloading = false;
        }, 600);
        this.$toast(err.message);
        if (err.code === 401 || err.code === 403) {
          this.$parent.login();
        }
      })

    },
    onPayConfirm() {
      let href = location.href;
      this.$toast.loading({message: '', duration: 8000, overlay: true});
      setTimeout(() => {
        location.replace(href.replace(new RegExp("h5pay_redirect=", "gm"), 'h5pay_redirected='));
      }, 1500);
    },
    onPayCancel() {
      let href = location.href;
      location.replace(href.replace(new RegExp("h5pay_redirect=", "gm"), 'h5pay_redirected='));
    },
    onBackClick() {
      this.$router.push({path: '/'});
    },
    onStartUpAppClick() {
      if (!this.$parent.isIos) {
        setTimeout(() => {
          this.$router.push({path: '/download-app'})
        }, 1000);
        location.href = 'scheme://ankichina/home';
      } else {
        location.href = 'https://mp.weixin.qq.com/s?__biz=MzU2OTg0NTAxMw==&mid=2247483671&idx=1&sn=f041df5e0f187f9c3de0d10ecac05d89&chksm=fcf9356dcb8ebc7bde77daa3c70830400b07b8859f25b62ae957e16df718d537592bf7f60c10#rd';
      }
    },
    onEvaluateClick(val) {
      if (this.$parent.loginUser) {
        request.request({
          url: '/card/evaluate',
          data: {cardId: this.cardId, value: val},
          method: 'post'
        }).then((data) => {
          this.card.evaluateUserCount = data.evaluateUserCount
          this.card.score = data.score
          this.card.evaluated = true
          this.$toast('评价已提交！');
        }).catch((err) => {
          if (err.code === 401 || err.code === 403) {
            this.$parent.login();
            return;
          }
          this.$toast(err.message)
        })
      } else {
        this.$parent.login();
      }
    },
    onTeamOrderClick() {
      if (!this.$parent.loginUser) {
        this.$parent.login();
        return;
      }

      if (this.$parent.appInner && this.$parent.isIos) {
        try {
          localStorage.setItem("jumpTeamOrder", 1);
          akBridge.callHandler("akOrder", 'team:' + this.cardId + '--ios')
        } catch (e) {
          this.$toast('调起下单失败')
        }
      } else {
        this.orderType = 'team';
        this.showPayOptions = true;
      }
    },
    onDownloadClick() {
      if (util.inWechat() && this.purchasedCard) {
        this.$parent.showCommonMask("微信内不支持下载", "请通过外部浏览器打开下载");
        return;
      }
      if (this.$parent.isIos && util.inQQ() && this.purchasedCard) {
        this.$parent.showCommonMask("QQ内不支持下载", "请通过外部浏览器打开下载");
        return;
      }

      if (this.$parent.loginUser) {
        if (this.card.owner || this.card.purchased) {
          this.onRealDownloadClick();
          return;
        }

        if (this.card.price > 0) {
          //判断是否在app内嵌h5
          if (this.$parent.appInner && this.$parent.isIos) {
            this.downloading = true;
            /*if (!util.compareVersionIfMax(this.$parent.appVersion, '1.8.9')) {
              this.$dialog.alert({
                message: '请前往appStore更新版本！',
              });
              return;
            }*/
            try {
              akBridge.callHandler("akOrder", this.cardId + '--' + (this.$parent.isIos ? 'ios' : 'android'))
            } catch (e) {
              this.$toast('调起下单失败')
            }
          } else {
            this.orderType = 'card';
            this.showPayOptions = true;
          }
        } else {
          this.onRealDownloadClick();
          // if (this.$cookies.get('appInner') === 'yes' || this.canDownload) {
          // } else {
          //   this.downloadApp = true;
          // }
        }
      } else {
        this.$parent.login();
      }
    },
    onRealDownloadClick(ignoreBigFile) {
      if (this.downloading) {
        return;
      }

      if (this.card.fileSize > 1024 * 1024 * 500 && !ignoreBigFile) {
        this.$dialog.confirm({
          message: '该文件超过500M下载时间可能较长，若长时间下载不成功可尝试用电脑浏览器访问【Anki中文资源网】登录下载再导入手机',
          confirmButtonText: '开始下载'
        }).then(() => {
          this.onRealDownloadClick(true);
        });
        return;
      }

      this.downloading = true;
      let requestStatus = 0;
      setTimeout(() => {
        if (requestStatus === 0) {
          if (this.card.fileSize > 1024 * 1024 * 30) {
            this.$toast.loading({message: '生成下载链接中，文件较大请耐心等候~', duration: 90000, overlay: true});
          } else {
            this.$toast.loading({message: '生成下载链接中，请稍候', duration: 60000, overlay: true});
          }
        }
      }, 1200);
      request.request({url: '/card/downloadUrl?cardId=' + this.cardId}).then((data) => {
        requestStatus = 1;
        this.$toast.clear();
        setTimeout(() => {
          this.downloading = false;
          if (!this.$parent.appInner) {
            this.$toast('准备下载文件');
          }
        }, 500);
        this.coin = data.coin;
        this.appCoin = data.appCoin;
        storage.setUserInfo({coin: this.coin});
        this.card.purchased = true;
        if (this.$parent.appInner && this.$parent.isIos) {
          try {
            akBridge.callHandler("akDownloadCard", {
              url: data.url,
              name: this.card.title
            })
          } catch (e) {
            this.$toast('调起下载失败')
          }
        } else {
          var a = document.createElement('a');
          a.setAttribute('href', data.url);
          a.setAttribute('download', data.filename);
          document.body.appendChild(a);
          a.click();
          setTimeout(() => {
            document.body.removeChild(a)
          }, 400)
        }
      }).catch((err) => {
        requestStatus = 1;
        this.$toast.clear();
        setTimeout(() => {
          this.downloading = false;
        }, 500);

        if (err.code === 401 || err.code === 403) {
          this.$parent.login();
        } else {
          this.$toast(err.message)
        }
      })
    },
    onReportClick() {
      this.$router.push({path: '/report', query: {cardId: this.cardId}})
    },
    onPublishClick() {
      if (this.$parent.appInner && this.$parent.isAndroid) {
        this.showPublishNotice = true;
      } else {
        location.href = '/card/edit/0'
      }
    },
    getData() {
      let requestStatus = 0;
      setTimeout(() => {
        if (requestStatus === 0) {
          this.$toast.loading({message: '', duration: 10000});
        }
      }, 1000);
      request.request({
        url: 'card/detail?cardId=' + this.$route.params.cardId + '&appInner=' + (this.$parent.appInner ? 'yes' : ''),
      }).then(data => {
        requestStatus = 1;
        if (data.team && data.team.status == 1) {
          if (localStorage.getItem("jumpTeamOrder") == '1') {
            this.$router.push({path: '/team/' + data.team.teamId});
            return;
          }
        }

        this.$toast.clear();
        this.userId = data.userId;
        this.userAvatar = data.userAvatar;
        this.userNickname = data.userNickname;
        this.userOther = data.userOther;
        this.recommend = data.recommend;
        this.card = data.card;
        this.team = data.team;
        this.allDecks = data.allDecks;
        this.underwayList = data.underwayList;
        if (this.underwayList && this.underwayList.length > 0) {
          this.randomUnderwayIndex = util.randomNum(0, this.underwayList.length - 1);
        }
        this.underwayUsers = data.underwayUsers;
        this.preview = data.preview;
        document.title = data.card.title;
        document.querySelector('meta[name="keywords"]').setAttribute('content', data.card.title);
        document.querySelector('meta[name="description"]').setAttribute('content', data.card.contentDesc);
        let doAction = sessionStorage.getItem('doAction');
        if (doAction) {
          sessionStorage.removeItem('doAction');
          if (doAction === 'download') {
            setTimeout(() => {
              if (this.card.purchased) {
                this.onDownloadClick();
              } else if (this.team && this.team.payCount >= this.team.users) {
                this.$dialog.alert({
                  message: '正在验证开团结果，请稍等一会刷新页面重试！',
                });
              }
            }, 500)
          } else if (doAction === 'newTeam') {
            this.onTeamOrderClick();
          }
        }

        if (util.inWechat()) {
          this.$parent.doWxShare(this.card.title, this.card.contentDesc,
            "https://file.jiushanteam.com/h5/card/" + this.cardId,
            "https://file.ankichinas.cn/server/static/image/share-icon.jpg");
        }
      }).catch(err => {
        requestStatus = 1;
        this.$toast.clear();
        this.$toast(err.message)
      })
    },
    onShareClick() {
      let shareData = {
        platform: ["QQ", "WEIXIN", "WEIXIN_CIRCLE", "COPY_LINK"],
        link: "https://file.jiushanteam.com/h5/card/" + this.cardId,
        title: this.card.title,
        desc: this.card.contentDesc,
        thumb: "https://file.ankichinas.cn/server/static/image/share-icon.jpg",//缩略图
      };

      if (akBridge && util.compareVersionIfMax(this.$parent.appVersion, '1.9.32')) {
        akBridge.callHandler("socialShare", JSON.stringify(shareData));
      } else if (typeof AnkiDroidJS !== 'undefined' && util.compareVersionIfMax(this.$parent.appVersion, '3.00.20')) {
        AnkiDroidJS.socialShare(JSON.stringify(shareData));
      } else if (util.inWechat()) {
        this.$parent.showWechatShareMask();
      } else {
        this.$parent.execCopy('https://' + location.host + '/card/' + this.cardId, '链接已复制');
      }
    },
    onCopyPayUrlClick() {
      this.$parent.execCopy(this.copyPayUrl, '链接已复制请前往手机浏览器粘贴支付');
    },
    onUserClick() {
      location.href = '/userCenter/' + this.userId;
    },
    onTitleClick() {
      this.$router.push({path: '/'})
    },
    onInputExchangeCodeClick() {
      if (this.$parent.loginUser) {
        this.showInputExchangeCode = true;
      } else {
        this.$parent.login();
      }
    },
    onUnderwayTeamClick(team) {
      this.$router.push({path: '/team/' + team.teamId})
    },
    onConfirmExchangeClick() {
      request.request({
        url: '/card/useExchangeCode',
        method: 'post',
        data: {
          cardId: this.cardId,
          code: this.exchangeCode
        }
      }).then((data) => {
        this.getData();
        this.$dialog.alert({message: '兑换成功，你可以下载卡牌了！'}).then(() => {
        })
      }).catch((data) => {
        this.$toast(data.message);
      })
    },
    onTeamRuleClick() {
      this.$dialog.alert({message: "不同卡牌的拼团时间、成团人数不一，参与拼团时请留意\n\n此卡牌拼团规则：\n" + this.card.teamInfo[0].users + "人成团 / " + this.card.teamInfo[0].timeout + "小时内未成团全额退款\n\n拼团团成功后如因卡牌质量问题，可在3天内向客服申请退款，逾期无法操作"}).then(() => {
      })
    },
    onTabClick(name, title) {
      console.log(name, title)
      if (name == 2) {
        this.isPreview = true;
      }
    },
    previewClose() {
      this.isPreview = false;
      this.active = 0;
    },
    getNotice() {
      let that = this;
      request.request({
        url: '/space.index/notice',
        params: {from: 1},
      }).then((data) => {
        console.log(data);
        that.notice = data;
        that.notice.message = that.notice.message.replace("点击查看详情", "");
      }).catch((e) => {
        console.log(e);
      })
    },
    onClickNotice(url) {
      //window.open(url);
      //this.onClickReadNotice();
      if (this.$parent.isIos && this.$parent.appInner) {
        //that.notice.message = '';
        if (util.compareVersionIfMax(this.$parent.appVersion, '1.13.2')) {
          akBridge.callHandler("openVIPPage");
        } else {
          this.$dialog.alert({message: '咦跳不过去了，请返回app设置页面查看详情哦'}).then(() => {
          })
        }
      } else {
        this.$router.push({'path': '/vip', query: {chan: 2}})
      }
    },
    onClickReadNotice() {
      let that = this;
      request.request({
        url: '/space.index/readNotice',
        params: {},
      }).then((data) => {
        console.log(data)
        setTimeout(function () {
          that.getNotice();
        }, 100);
      }).catch((e) => {
        console.log(e);
      })
    },
  },
  destroyed() {
    if (this.underwayCountDowner) {
      clearInterval(this.underwayCountDowner);
      this.underwayCountDowner = null;
    }
  },
  mounted() {
    this.getNotice();
    this.openFromAppInner = this.$route.query['fromAppInner'] === 'yes';
    this.getData();
    if (this.$parent.loginUser) {
      this.coin = this.$parent.loginUser.coin;
    }

    if (this.$parent.authToken) {
      request.request({url: 'user/getCoin',}).then((data) => {
        this.coin = data.coin;
        this.rechargePackage = data.rechargePackage;
      })
      if (this.$parent.appInner && this.$parent.isIos) {
        request.request({url: 'user/getAppCoin',}).then((data) => {
          this.appCoin = data.coin;
        })
      }
    }

    if (akBridge) {
      akBridge.registerHandler("akSetCardStatus", (responseData) => {
        this.hasImport = parseInt(responseData) === 1;
      });

      setTimeout(() => {
        akBridge.callHandler("akGetCardId", this.cardId)
      }, 10);
    }

    if (this.$route.query['h5pay_redirect'] === 'yes') {
      setTimeout(() => {
        this.showPayResult = true;
      }, 100)
    }

    let shareInfo = this.$route.query['share_info'] || sessionStorage.getItem('share_info');
    if (shareInfo) {
      request.request({
        url: '/card/checkDownload',
        params: {
          cardId: this.cardId,
          shareInfo: shareInfo
        }
      }).then((data) => {
        this.canDownload = data.ret;
      })
    }

    if (!this.underwayCountDowner) {
      this.underwayCountDowner = setInterval(() => {
        if (this.underwayList && this.underwayList.length > 0) {
          for (let i = 0; i < this.underwayList.length; i++) {
            this.underwayList[i].countDown--;
            if (this.underwayList[i].countDown <= 0) {
              this.underwayList[i].countDownTxt = '00:00:00';
            } else {
              let H = parseInt(this.underwayList[i].countDown / 3600);
              let countDownH = H < 10 ? '0' + H : H;
              let M = parseInt((this.underwayList[i].countDown % 3600) / 60);
              let countDownM = M < 10 ? '0' + M : M;
              let S = parseInt(this.underwayList[i].countDown % 60);
              let countDownS = S < 10 ? '0' + S : S;
              this.underwayList[i].countDownTxt = countDownH + ':' + countDownM + ':' + countDownS;
            }
          }
        }
      }, 1000);
    }
  }
}


</script>

<style>

#notice {
  background-color: #28BEA0;
  vertical-align: middle;
  /* 设置容器大小 */

  /* 设置两个背景图像 */
  background-image: url('/h5/static/images/home_bg_one.png'), /* 第一个背景图像 */ url('/h5/static/images/home_bg_two.png'); /* 第二个背景图像 */

  /* 设置背景图像的位置 */
  background-position: left top, /* 第一个背景图像的位置 */ right bottom; /* 第二个背景图像的位置 */

  /* 设置背景图像的重复 */
  background-repeat: no-repeat, /* 第一个背景图像不重复 */ no-repeat; /* 第二个背景图像重复 */

  /* 设置背景图像的大小 */
  background-size: auto 26%, /* 第一个背景图像宽度自动，高度100% */ auto 26%; /* 第二个背景图像宽度50%，高度自动 */
}

.safe-bottom {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.van-tab {
  font-size: 16px !important;
  font-weight: bold !important;
}

.arrow-div {
  position: relative;
  background-color: #ffffff;
  border: 0px solid #dddddd;
  margin-bottom: 1px;
}

.arrow-div:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  border: 1px solid #ddd;
  border-color: transparent #ddd #ddd transparent;
  transform: rotate(45deg);
  right: 30px;
  bottom: -4px;
  background: #fff;
}

</style>
